<template>
	<div id="account-payment-history">
		<div class="columns is-mobile" v-for="(line, index) in operations" v-bind:key="index">
			<div class="column is-two-quarters">
				{{line.created_at | formatDate}}
			</div>
			<div class="column is-one-quarter type">
				{{line.type}}
			</div>
			<div class="column is-one-quarter">
				{{ new Intl.NumberFormat('da-DK', { style: 'currency', currency: 'DKK' }).format(line.amount/100) }}
			</div>
		</div>
		<div v-if="pending && this.operations !== false">
			Loading payment history
		</div>
		<div v-else-if="this.operations === false">
			<span class="has-text-danger"><i class="far fa-times-circle"></i> Payment was abandoned</span>
		</div>
	</div>
</template>
<script>
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
});
export default {
  name: "payment_history",
  props: {
		request_id: { type: String, default: "", required: true },
		payment_id: { type: String, default: "", required: true }
  },
  data() {
    return {
			pending: true,
			payment: {
				accepted: null,
				created_at: null,
				updated_at: null
			},
			operations: [],
		};
	},
	methods: {
		async getHistory() {
			this.pending = true;
			if(this.payment_id) {
				try {
					const response = await axios.get('/account/requests/'+this.request_id+'/payment/'+this.payment_id+'/status')
					if(response.data.operations.length > 0) {
						this.operations = response.data.operations;
						this.payment = {
							accepted: response.data.accepted,
							created_at: moment(response.data.created_at),
							updated_at: moment(response.data.updated_at),
						}
					} else if(response.data.accepted === false) {
						this.operations = false;
					}
					this.pending = false;
				} catch (error) {
					console.error(error);
				}
			} else {
				this.operations = false;
			}
		}
	},
  mounted() {
    this.getHistory();
  }
};
</script>