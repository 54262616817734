<template>
  <div>
    <div>
      <vue-dropzone
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
        v-on:vdropzone-sending="sendingEvent"
        @vdropzone-success="fileUploadComplete"
        @vdropzone-file-added="vfileAdded"
      ></vue-dropzone>
    </div>
    <div class="product-gallery">
      <SlickList
        class="list-group-list"
        :lockToContainerEdges="true"
        :useWindowAsScrollContainer="true"
        :useDragHandle="true"
        @input="newlist"
        axis="xy"
        v-model="gallery"
      >
        <SlickItem
          v-for="(img, $index) in gallery"
          :index="$index"
          :key="$index"
          class="list-group-item-list"
        >
          <a v-on:click="removeImage(img, $index)" class="delete"></a>
          <i v-handle class="has-background-grey handle fas fa-arrows-alt"></i>
          <img :src="img" style="height: 150px" />
        </SlickItem>
      </SlickList>
    </div>
  </div>
</template>

<script>
import { SlickList, SlickItem, HandleDirective } from "vue-slicksort";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "upload-component",
  components: {
    vueDropzone: vue2Dropzone,
    SlickItem,
    SlickList,
  },
  directives: { handle: HandleDirective },
  data() {
    return {
      dropzoneOptions: {
        url: "/account/image/store",
        thumbnailWidth: 150,
        maxFilesize: 2,
        addRemoveLinks: true,
        dictDefaultMessage:
          "<i class='fas fa-cloud-upload-alt'></i> Click for upload or drag files here",
        headers: { "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute("content") }
      },
      fileAdded: false,
      gallery: [],
      useDragHandle: true,
    };
  },
  props: {
    id: {
      type: String
    },
  },
  methods: {
    vfileAdded(file) {
      this.fileAdded = true;
    },
    sendingEvent(file, xhr, formData) {
      formData.append("product", this.id);
    },
    fileUploadComplete(file, response) {
      this.gallery.push(response.res.url);
      this.$refs.myVueDropzone.removeFile(file);
      this.$buefy.snackbar.open({
        duration: 4000,
        message: `Image successfully added.`,
        position: "is-bottom-right",
        queue: false,
        type: "is-success",
      });
    },
    getGallery() {
      axios
        .get("/account/image/get/" + this.id )
        .then((response) => {
          if (response.data.gallery) {
            this.gallery = response.data.gallery;
          }
        });
    },
    removeImage(url, index) {
      var r = confirm("Delete image?");
      if (r == true) {
        axios
          .post("/account/image/remove", {
            image: url,
            gallery: this.gallery,
            product: this.id,
          })
          .then((response) => {
            Vue.delete(this.gallery, index);
            this.$buefy.snackbar.open({
              duration: 4000,
              message: `Image deleted.`,
              position: "is-bottom-right",
              queue: false,
              type: "is-success",
            });
          });
      }
    },
    newlist(list) {
      axios
        .post("/account/image/order", { gallery: list, product: this.id })
        .then(() => {
          this.$buefy.snackbar.open({
            duration: 4000,
            message: `Gallery updated.`,
            position: "is-bottom-right",
            queue: false,
            type: "is-success",
          });
        });
    },
  },
  mounted: function () {
    this.getGallery();
  },
};
</script>