<template>
  <div class="modal-card mx-0" style="width: auto">
    <section class="modal-card-body px-0">
      <b-field class="mb-4" grouped group-multiline>
        <b-field :label="getField('calendar-action')" class="mb-4 is-block">
          <b-select placeholder="Options" v-model="selected" id="date-actions" expanded>
            <option value="disabledDates">{{getField('calendar-close')}}</option>
            <option value="availableDates">{{getField('calendar-open')}}</option>
          </b-select>
        </b-field>
        <b-field :label="getField('calendar-from')" class="is-block">
          <b-input type="date" ref="fromDate" id="from-date" :min="firstDate" v-model="startDate" v-on:blur="startChanged()" />
        </b-field>
        <b-field :label="getField('calendar-to')">
          <b-input type="date" ref="toDate" id="to-date" v-model="endDate" />
        </b-field>
      </b-field>

      <b-tabs v-model="activeTab">
        <b-tab-item :label="getField('calendar-week')">
          <div class="form-group row">
            <div class="col-lg-4 col-sm-12">
              <input type="checkbox" name="monday" id="monday" v-model="days" value="1"> <label class="form-check-label" for="monday">{{ labels[0] }}</label><br>
              <input type="checkbox" name="tuesday" id="tuesday" v-model="days" value="2"> <label class="form-check-label" for="tuesday">{{ labels[1] }}</label><br>
              <input type="checkbox" name="wednesday" id="wednesday" v-model="days" value="3"> <label class="form-check-label" for="wednesday">{{ labels[2] }}</label>
            </div>
            <div class="col-lg-4 col-sm-12">
              <input type="checkbox" name="thursday" id="thursday" v-model="days" value="4"> <label class="form-check-label" for="thursday">{{ labels[3] }}</label><br>
              <input type="checkbox" name="friday" id="friday" v-model="days" value="5"> <label class="form-check-label" for="friday">{{ labels[4] }}</label><br>
              <input type="checkbox" name="saturday" id="saturday" v-model="days" value="6"> <label class="form-check-label" for="saturday">{{ labels[5] }}</label>
            </div>
            <div class="col-lg-3 col-sm-12">
              <input type="checkbox" name="sunday" id="sunday" v-model="days" value="7"> <label class="form-check-label" for="sunday">{{ labels[6] }}</label>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item :label="getField('calendar')">
          <v-calendar ref="calendar" v-model="dates" :min-date="calendarStartDate" :max-date="calendarEndDate" :attributes="attributes" @dayclick="onDayClick" timezone="Atlantic/Faroe" :first-day-of-week="2" title-position="left" :columns="$screens({ default: 1, lg: 2 })" is-inline is-expanded />
        </b-tab-item>
      </b-tabs>
      <div class="modal-footer px-0">
        <button type="button" v-on:click="saveDates()" :class="buttonObject" :disabled="disabled">{{working}}</button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ProductCalendarComponent',
  props: {
    product: {
      type: String
    },
    startHour: {
      type: String | Number
    },
    startMinute: {
      type: String | Number
    },
    dayLabels: {
      type: String
    },
    fields: {
      type: String
    }
  },
  data () { 
    return {
      activeTab: 0,
      calendar: [],
      days: [],
      labels: [],
      formats: {
        title: 'MMMM YYYY',
        input: ['YYYY-DD-MM HH:mm']
      },
      selectAttribute: {
        highlight: {
          backgroundColor: '#419e6b'
        }
      },
      first: 2,
      firstDate: new Date(),
      calendarStartDate: null,
      calendarEndDate: null,
      selected: 'availableDates',
      startDate: null,
      endDate: null,
      working: '',
      disabled: false,
      buttonObject: {
        'button': true,
        'is-success': true,
        'is-loading': false
      },
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  },
  computed: {
    dates() {
      return this.calendar.map(day => day.date);
    },
    attributes() {
      return this.dates.map(date => ({
        highlight: true,
        dates: date
      }));
    }
  },
  methods: {
    getField (name) {
      const field = JSON.parse(this.fields);
      return field[name];
    },
    onDayClick(day) {
      const idx = this.calendar.findIndex(d => d.id === day.id);
      if (idx >= 0) {
        this.calendar.splice(idx, 1);
      } else {
        this.calendar.push({
          id: day.id,
          date: day.date,
        });
      }
    },
    startChanged() {
      let start = moment(this.startDate);
      this.$refs.calendar.move(start.format('YYYY-MM-DD'));
      this.calendarStartDate = start.format('YYYY-MM-DD');
      let end = start.add(3,'months');
      this.calendarEndDate = end.format('YYYY-MM-DD');;
      this.endDate = end.format('YYYY-MM-DD');;
    },
    saveDates() {
      this.working = 'Please wait...';
      this.disabled = true;
      this.buttonObject = {
        'button': true,
        'is-success': true,
        'is-primary': true,
        'is-loading': true
      }				
      axios.post('/account/products/' + this.product + '/dates', {
        selected: this.selected,
        start: this.startDate,
        end: this.endDate,
        starthour: this.startHour,
        startminute: this.startMinute,
        days: this.days,
        calendar: this.calendar
      }).then(response => {
        this.buttonObject['is-loading'] = false;
        this.working = this.getField('calendar-save');
        this.disabled = false;
        this.buttonObject = {
          'button': true,
          'is-success': true,
          'is-loading': false
        }
        this.$buefy.snackbar.open({
          duration: 4000,
          message: this.getField('calendar-saved'),
          position: 'is-bottom-right',
          queue: false,
          type: 'is-success'
        });
        this.calendar = [];
      });
    }
  },
  created() {
    let start = moment();
    this.calendarStartDate = start.format('YYYY-MM-DD');
    this.startDate = start.format('YYYY-MM-DD');
    this.calendarEndDate = start.add(3, 'month').format('YYYY-MM-DD');
    this.endDate = start.format('YYYY-MM-DD');
    this.labels = JSON.parse(this.dayLabels);
    this.working = this.getField('calendar-save');
  }
}
</script>

<style>

</style>