<template>
<div>
    <figure class="image is-158x158">
        <img class="is-rounded" id="profileImage" :src="profile">
    </figure>
    <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-sending="sendingEvent" @vdropzone-success="fileUploadComplete" @vdropzone-file-added="vfileAdded"></vue-dropzone>
</div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'app',
  components: {
    vueDropzone: vue2Dropzone
  },
  props: ['image'],
  data: function () {
    return {
        profile: this.image,
        dropzoneOptions: {
          url: '/account/profile/store-profile-image',
          thumbnailWidth: 128,
          maxFilesize: 2,
          addRemoveLinks: true,
          dictDefaultMessage: "<i class='fas fa-cloud-upload-alt'></i> Click for upload or drag profile image here",
          headers: { "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]').content }
        },
        fileAdded: false,
        gallery: []
    }
  },
  methods: {
    vfileAdded(file) {
        this.fileAdded = true
    },
    sendingEvent (file, xhr, formData) {
        formData.append('profile', this.id);
    },
    fileUploadComplete(file, response) {
        this.$refs.myVueDropzone.removeFile(file);
        this.profile = response.url;
    },
  }
}
</script>