<template>
<section>
  <b-button
    label="Create event"
    type="is-success"
    @click="isComponentModalActive = true" />
  <b-modal
    v-model="isComponentModalActive"
    has-modal-card
    :can-cancel="true">
    <div class="modal-card" style="min-width: 600px">
      <header class="modal-card-head">
        <h5 class="modal-card-title" id="modalNewProductLabel">Create event</h5>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label for="title">Title</label>
          <b-input type="text" class="form-control" id="name" placeholder="Title" v-model="input.name"></b-input>
        </div>

        <div class="field">
          <label for="description">Description</label>
          <b-input type="textarea" id="description" placeholder="Describe what you are serving for your guests. Perhaps a short story and the menu." rows="8" v-model="input.description"></b-input>
        </div>

        <div class="field">
          <label for="information">Information</label>
          <b-input type="textarea" id="description" placeholder="Here you can write some practical information for your guests. It could for example be how to get to your location." rows="8" v-model="input.information"></b-input>
        </div>

        <div class="columns">
          <div class="column">
            <label for="start" class="col-form-label">Start (first date and time)</label>
            <b-input type="datetime-local" class="form-control" id="time" placeholder="Start" v-model="input.start"></b-input>
          </div>
          <div class="column">
            <label for="duration" class="col-form-label">Duration (2.5 = 2 hours 30 minutes)</label>
            <b-input type="number" class="form-control" id="duration" placeholder="Duration" v-model="input.duration"></b-input>
          </div>
        </div>
        <div class="columns mt-3">
          <div class="column">
            <label for="price-adults" class="col-form-label">Price (adults)</label>
            <b-input type="number" class="form-control" id="price-adults" min="0" v-model="input.price"></b-input>
          </div>
          <div class="column">
            <label for="price-children" class="col-form-label">Price (children)</label>
            <b-input type="number" class="form-control" id="price-child" min="0" v-model="input.price_children"></b-input>
          </div>
          <div class="column">
            <label for="mofp" class="col-form-label">Min. persons</label>
            <b-input type="number" class="form-control" min="1" id="mofp" v-model="input.mofp"></b-input>
          </div>
          <div class="column">
            <label for="nofp" class="col-form-label">Max. persons</label>
            <b-input type="number" class="form-control" id="nofp" min="1" v-model="input.nofp"></b-input>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button type="button" v-on:click="saveProduct()" v-bind:class="buttonObject" :disabled="disabled">Save product</b-button>
      </footer>
    </div>
  </b-modal>
</section>
</template>

<script lang="ts">
import Vue from 'vue'
import axios from 'axios';
export default Vue.extend({
  name: 'CreateEventComponent',
  data: () => {
    return {
      isComponentModalActive: false,
      active: false,
      input: {
        name: null,
        description: null,
        information: null,
        price: 0,
        price_children: 0,
        nofp: 0,
        mofp: 2,
        start: new Date().toISOString().substr(0, 10),
        duration: 0,
        firstdate: null,
        location: {
          latitude: null,
          longitude: null
        }
      },
      disabled: false,
      buttonObject: {
        'button': true,
        'is-success': true
      }
    }
  },
		methods: {
			saveProduct() {
				this.disabled = true;
				this.buttonObject = {
					'button': true,
					'is-primary': true,
					'is-loading': true
				}
				axios.post('/account/products', {
					data: this.input
				}).then(response => {
					this.disabled = false;
					this.buttonObject = {
						'button': true,
						'is-primary': true,
						'is-loading': false
					}
					if(response.status === 200) {
						window.location.href = response.data.url;
					} else {
						console.log(response);
						alert('Alert: ' + response.status)
					}
				}).catch((error) => {
					this.disabled = false;
					this.buttonObject = {
						'button': true,
						'is-primary': true,
						'is-loading': false
					}
					alert(error);
				})
			}
		}
});
</script>