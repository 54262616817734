<template>
  <form @submit.prevent="handleSubmitForm">
    <input type="hidden" name="_token" :value="token" />
    <b-button type="is-black" class="button" :loading="loading" :disabled="review_requested" @click="handleSubmitForm">Request review</b-button>
    <div v-if="message !== null">
      {{ message }}
    </div>
  </form>
</template>

<script>
export default {
  name: 'RequestReview',
  props: {
    requestId: String,
    token: String
  },
  data: () => {
    return {
      review_requested: false,
      loading: false,
      message: null
    }
  },
  methods: {
    async handleSubmitForm () {
      try {
        this.loading = true;
        await axios.get('/reviews/request/' + this.requestId).then((result) => {
          this.loading = false;
          this.review_requested = true;
          this.message = result.data.message;
        });
      }
      catch (error) {
        this.loading = false;
        this.review_requested = false;
        this.message = error;
      }
    }
  }
}
</script>