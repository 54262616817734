<template>
  <vue-editor v-model="contentData" :editor-toolbar="customToolbar" @blur="onEditorBlur" />
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: { VueEditor },
  props: {
      'content': String,
      'id': String,
      'toolbar': Array
  },
  data: function () {
    return {
        contentData: this.content,
        customToolbar: this.toolbar
    }
  },
  methods: {
    onEditorBlur(quill) {
      let string = (quill.root.innerHTML === '<p><br></p>') ? null : quill.root.innerHTML;
      this.$emit('updated', { value: string, id: this.id });
    },
  }
};
</script>