/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');

window.Vue = require('vue');
window.moment = require('moment');

import Buefy from 'buefy'
Vue.use(Buefy)

window.PaymentHistoryComponent    = require('./components/account/PaymentHistoryComponent.vue').default;
window.imageComponent             = require('./components/account/UploadComponent.vue').default;
window.profileImageComponent      = require('./components/account/ImageProfileComponent.vue').default;
window.editorComponent            = require('./components/account/EditorComponent.vue').default;
window.RatingComponent            = require('./components/RatingComponent.vue').default;
window.productCalendarComponent   = require('./components/account/ProductCalendarComponent.vue').default;
window.CreateEventComponent       = require('./components/account/CreateEventComponent.vue').default;
window.RequestReviewComponent     = require('./components/account/RequestReviewComponent.vue').default;

document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }
  
});